<template>
  <div class="about">
		<loader :is-ready="isReady" :is-disable="isDisable"></loader>
		<div>
			<h2>
				<span class="txt-highlight">Who</span> I am
			</h2>
			<p>
				<img alt="me" src="../assets/me.jpg" align="left">
				<span class="txt-highlight">Ciao!</span>
				My name is Lorenzo and I’m a <span class="txt-highlight">web developer</span> since 2009 (YIKES!). In this timespan I had the opportunity to work on various projects with different technologies.
			</p>
			<br>
			<p>
				I'm a fullstack developer focused mainly on the <span class="txt-highlight">frontend</span>. It’s there <span class="txt-highlight">where I feel more comfortable</span> and it’s there where I get the most satisfaction.
				Still, I work with the <span class="txt-highlight">backend</span> almost every day, <span class="txt-highlight">but it's definitely not my passion</span>. I know how to deploy a web application, configure a web server, create API and setup a database. But at the end of the day <span class="txt-highlight">I have more fun working on the frontend</span>.
			</p>
			<br>
			<p>
				For details about my skills check out the page <router-link to="/what-i-do"><span>What</span> I do</router-link>.
			</p>
			<br>
			<br>
			<h3>
				My <span class="txt-highlight">journey</span>
			</h3>
			<p>
				I started my career by creating <span class="txt-highlight">platforms for certified online courses</span> for doctors with C#, then I moved on to create <span class="txt-highlight">interactive presentations</span> for iPad (the very first version, a pain!) of medical material mainly taking care of the UI side in <span class="txt-highlight">the old way: HTML , CSS, Javascript</span>.
			</p>
			<br>
			<p>
				This is how my engagement with frontend began. In 10+ years I worked and shipped <span class="txt-highlight">videogames for mobile devices, web applications, e-commerce, intranet and portals</span> using various frameworks like: <span class="txt-highlight">Vuejs, jQuery, Backbone, Angular, D3 and many others</span>.
			</p>
			<br>
			<p>
				On the other side, I never spent too much time on the backend until 2015. That year I started to work as a <span class="txt-highlight">freelancer</span> so I had to learn some skills on the backend to be able to get interesting projects. In the last years I worked with different backend technologies like: <span class="txt-highlight">Laravel, Php, Python, Node</span> on different systems.
			</p>
			<br>
			<p>
				Of course there are <span class="txt-highlight">CMS</span> in my background, classic ones like <span class="txt-highlight">Wordpress and Joomla</span>, different one like <span class="txt-highlight">OctoberCMS</span> and even headless CMS like <span class="txt-highlight">Directus</span>.
			</p>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue'

export default {
	name: 'Home',
	components: {
		Loader
	},
	data () {
		return {
			isReady: false,
			isDisable: false
		}
	},
	head: {
		title: {
			inner: 'Lorenzo Cogliati - Web Developer'
		},
		meta: [
			{ name: 'application-name', content: 'Cogliawho' },
			{ name: 'description', content: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js' },
			// Twitter
			{ name: 'twitter:title', content: 'Lorenzo Cogliati - Web Developer' },
			// with shorthand
			{ n: 'twitter:description', c: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js'},
			// Google+ / Schema.org
			{ itemprop: 'name', content: 'Lorenzo Cogliati - Web Developer' },
			{ itemprop: 'description', content: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js' },
			{ p: 'og:image', c: 'https://cogliawho.it' + require('@/assets/coglia_who_logo.png') },
		]
	},
	beforeRouteLeave (to, from, next) {
		let self = this
		this.isDisable = false
		setTimeout(function () {
      self.isReady = false
    }, 50)
    setTimeout(function () {
      next()
    }, 650)
  },
  mounted () {
		let self = this
    setTimeout(function () {
			self.isReady = true
    }, 100)
    setTimeout(function () {
			self.isDisable = true
    }, 750)
  }
}
</script>

<style lang="scss">
	.about{
		height: 100%;
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		overflow: auto;
		padding-right: 10px;
		h2, h3{
			margin-bottom: 2rem;
		}
		p{
			text-align: justify;
			padding-right: 10px;
		}
		img{
			width: 38%;
			min-width: 200px;
			margin-top: 5px;
			padding-right: 15px;
			padding-bottom: 0px;
		}
	}
	@media (max-width: 1023px)  {
		.about{
			padding-right: 0px;
			p{
				padding-right: 0px;	
			}
		}
	}
</style>
