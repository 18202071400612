<template>
  <div class="loader-transition-wrap" v-bind:class="{'hide-animation': isReady, 'disable': isDisable}">
    <div class="shadow-loader go-left" style="top: 0px; left: 0px;"></div>
    <div class="shadow-loader go-up" style="top: 0px; left: 25%;"></div>
    <div class="shadow-loader go-right" style="top: 0px; left: 50%;"></div>
    <div class="shadow-loader go-down" style="top: 0px; left: 75%;"></div>

    <div class="shadow-loader go-left" style="top: 25%; left: 0px;"></div>
    <div class="shadow-loader go-up" style="top: 25%; left: 25%;"></div>
    <div class="shadow-loader go-right" style="top: 25%; left: 50%;"></div>
    <div class="shadow-loader go-down" style="top: 25%; left: 75%;"></div>

    <div class="shadow-loader go-left" style="top: 50%; left: 0px;"></div>
    <div class="shadow-loader go-up" style="top: 50%; left: 25%;"></div>
    <div class="shadow-loader go-right" style="top: 50%; left: 50%;"></div>
    <div class="shadow-loader go-down" style="top: 50%; left: 75%;"></div>

    <div class="shadow-loader go-left" style="top: 75%; left: 0px;"></div>
    <div class="shadow-loader go-up" style="top: 75%; left: 25%;"></div>
    <div class="shadow-loader go-right" style="top: 75%; left: 50%;"></div>
    <div class="shadow-loader go-down" style="top: 75%; left: 75%;"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isReady: Boolean,
    isDisable: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader-transition-wrap{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden;
  display: block;
  &.disable{
    display: none;
  }
  &.hide-animation{
    .shadow-loader{
      // box-shadow: inset 0 0px 0px #efeeec!important;
      transform: scale(0);
      border-radius: 50%;
    }
  }
  .shadow-loader{
    position: absolute;
    left: 0%;
    top: 0px;
    // transform: translate(-50%, 0);
    width: 25%;
    height: 25%;
    z-index: 99;
    // box-shadow: inset 0px -500px 130px #efeeec;
    background-color: #efeeec;
    transform: scale(1);
    border-radius: 0;
    transition: all .6s cubic-bezier(0.25, 0.1, 0.25, 1);
    &.go-left{
      // box-shadow: inset 500px 0px 130px #efeeec;
    }
    &.go-right{
      // box-shadow: inset -500px 0px 130px #efeeec;
    }
    &.go-up{
      // box-shadow: inset 0px 500px 130px #efeeec;
    }
    &.go-down{
      // box-shadow: inset 0px -500px 130px #efeeec;
    }
  }
}
</style>
